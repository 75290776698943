import request from "@/utils/request";
import qs from "qs";
// 广告
export const findAdById = (params) => {
  return request({
    url: "wx/findAdById",
    method: "get",
    params,
  });
};
// 专题ID获取详情
export const getTSpecificById = (params) => {
  return request({
    url: "wx/getTSpecificById/",
    method: "get",
    params,
  });
};
// addmodel3
export const addmodel3 = (data) => {
  return request({
    url: "wx/addmodel3",
    method: "post",
    data: qs.stringify(data),
  });
};
