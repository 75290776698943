<template>
  <div class="m1">
    <div class="m1_h">
      <div class="m1_video">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
      </div>
      <div class="m1_img">
        <van-image width="100%" fit="cover" :src="img1" />
        <van-image width="100%" fit="cover" :src="img2" />
      </div>
      <div class="m1_text" v-html="content"></div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage } from "vant";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import { getTSpecificById } from "@/api/model";
import getJSSDK from "@/utils/wxShare";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    videoPlayer,
    VanImage,
    copyrightIp,
  },
  data() {
    return {
      // 视频
      playerOptions: {
        height: 200,
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: require("@/assets/img/about_01.jpg"), // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      img1: "",
      img2: "",
      content: "",
    };
  },
  created() {
    this.getTSpecific();
  },
  methods: {
    getTSpecific() {
      let tspecificId = window.location.hash.split("?")[1].split("=")[1];
      getTSpecificById({ tspecificId }).then((res) => {
        let data = res.data.data;
        getJSSDK({
          title: data.tspecific.specificName || "中智薪税",
          link: this.$route.fullPath,
        });

        this.playerOptions.sources.push({
          type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: data.tspecificTempItems[0]?.itemValue, // url地址
        });
        this.img1 = data.tspecificTempItems[1]?.itemValue;
        this.img2 = data.tspecificTempItems[2]?.itemValue;
        this.content = data.tspecificTempItems[3]?.itemValue;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.m1 {
  height: 100%;
  .m1_h {
    min-height: calc(100% - 44px);
  }
}
.m1_video {
  // 调整播放器样式
  ::v-deep .video-js {
    width: 100%;
    .vjs-poster {
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .vjs-big-play-button {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      left: 50%; /* 定位父级的50% */
      top: 50%;
      transform: translate(-50%, -50%); /*自己的50% */
      border: 5px solid #fff;
      //   font-size: 5em;
      //   line-height: 1em;
      .vjs-icon-placeholder {
        width: 100%;
        height: 100%;
        display: block;
        line-height: 51px;
        font-size: 33px;
      }
    }
  }
}
.m1_img {
  width: 100%;
  padding-top: 10px;
  .van-image {
    width: 100%;
    height: 130px;
    margin-bottom: 10px;
  }
}
.m1_text {
  width: 100%;
  padding: 10px;
  background: #fff;
}
</style>
